@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: sans-serif;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  /* Set width */
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  /* Background color */
  border-radius: 2px;
  border: 1px solid #eaeaea;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #DBDCEC;
  /* Scrollbar color */
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c2c3d4;
  /* Slightly darker on hover */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
:where(.css-dev-only-do-not-override-240cud).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
:where(.css-dev-only-do-not-override-240cud).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
:where(.css-dev-only-do-not-override-240cud).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  font-size: 12px !important;
}

a {
  color: #1677ff;
}

.calcHeight {
  height: calc(100vh - 185px);
}

@media screen and (max-width: 1215px) {
  .calcHeight {
    height: calc(100vh - 225px);
  }
}

@media screen and (max-width: 1022px) {
  .calcHeight {
    height: calc(100vh - 285px);
  }
}